module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"qualifytodayauto.com","short_name":"qualifytodayauto.com","start_url":"/","version":"0.0.2","permissions":["gcm","storage","notifications"],"gcm_sender_id":"395966986145","gcm_user_visible_only":true,"display":"standalone","icon":"/opt/atlassian/pipelines/agent/build/data/sites/insurance/qualifytodayauto.com/assets/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2ab2827cf5b7998517355144c1661125"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
